
import { defineComponent, ref } from 'vue';
import { useQuery, useMutation } from 'villus';
import AdminButton from '../components/AdminButton.vue';

export default defineComponent({
  components: { AdminButton },

  props: {
    isAdmin: Boolean,
    me: Object,
  },

  setup(props) {
    const AllPosts = `
      query {
        posts {
          id
          title
        }
      }`;

    const { data: postsResult, execute: fetchPosts } = useQuery({ query: AllPosts, cachePolicy: 'network-only' });

    const DeletePost = `
      mutation DeletePost($id:ID!) {
        deletePost(id: $id) {
          id
        }
      }`;

    const { execute } = useMutation(DeletePost);

    const deletePost = (post: Post) => {
      if (window.confirm('Zeker weten?')) { // eslint-disable-line no-alert
        execute({ id: post.id })
          .then(() => fetchPosts());
      }
    };

    return {
      postsResult,
      deletePost,
      AdminButton,
    };
  },
});
