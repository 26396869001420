<template>
  <ul v-if="isAdmin || isSuperAdmin" class="sub-navigation">
    <template v-if="['/users', '/users/archive'].indexOf($route.fullPath) >= 0">
      <li>
        <router-link :to="{ name: 'Users' }">
            Actieve leden
          <img class="icon" src="../assets/iconmonstr-user-8-240.png">
        </router-link>
      </li>
      <li>
        <router-link :to="{ name: 'ArchivedUsers' }">
            Uitgeschreven leden
          <img class="icon" src="../assets/iconmonstr-user-8-240.png">
        </router-link>
      </li>
      <li>
        <router-link :to="{ name: 'NewUser' }">
            Nieuw lid toevoegen
          <img class="icon" src="../assets/iconmonstr-user-8-240.png">
        </router-link>
      </li>
    </template>
    <li v-if="isSuperAdmin && $route.fullPath == '/groups'">
      <router-link :to="{ name: 'NewGroup' }">
        Nieuwe groep toevoegen
        <img class="icon" src="../assets/iconmonstr-user-23-240.png">
      </router-link>
    </li>
    <li v-if="$route.fullPath == '/posts'">
      <router-link :to="{ name: 'NewPost' }">
        Nieuw bericht toevoegen
        <img class="icon" src="../assets/iconmonstr-email-15-240.png">
      </router-link>
    </li>

    <template v-if="$route.fullPath.startsWith('/finance')">
      <li>
        <router-link :to="{ name: 'CreateSepaExport' }">
          Betalingen exporteren
          <img class="icon" src="../assets/iconmonstr-paper-plane-9-240.png">
        </router-link>
      </li>

      <li>
        <router-link :to="{ name: 'CreateDeclaration' }">
          Declaraties
          <img class="icon" src="../assets/iconmonstr-paper-plane-9-240.png">
        </router-link>
      </li>

      <template v-if="isSuperAdmin">
        <li v-if="isSuperAdmin">
          <router-link :to="{ name: 'Invoices' }">
            Facturen
          <img class="icon" src="../assets/iconmonstr-note-31-240.png">
          </router-link>
        </li>

        <li v-if="isSuperAdmin && $route.fullPath.startsWith('/finance/invoices')">
          <router-link :to="{ name: 'CreateInvoice' }">
            Nieuwe factuur
          </router-link>
        </li>
      </template>
    </template>

    <li v-if="$route.fullPath == '/files' && isSuperAdmin">
      <router-link :to="{ name: 'NewFile' }">
        Nieuw document
      <img class="icon" src="../assets/iconmonstr-note-31-240.png">
      </router-link>
    </li>
  </ul>
</template>

<script lang="ts">
export default {
  props: {
    isAdmin: Boolean,
    isSuperAdmin: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.router-link-exact-active {
  background: #ccc;
}
</style>
